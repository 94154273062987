import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { FormHeader } from '../../Form/Header/FormHeader'
import { FormContainer } from '../../Form/Container/FormContainer'
import { FormTextField } from '../../Form/Fields/Text/FormTextField'
import { PrivacyName, useDataRemoval } from '../context'
import moment from 'moment'
import Notifications from '../../../shared/notifications'
import { FormDateField } from '../../Form/Fields/Date/FormDateField'

const styles = require('./PersonalInformation.module.scss')

const PersonalInfoImage = require('../../../assets/images/personal-info.svg')
const FormRemove = require('../../../assets/images/form-remove.svg')

type Props = {
  onStepComplete: () => void;
};

const PersonalInformation: FC<Props> = ({ onStepComplete }) => {
  const dataRemoval = useDataRemoval()
  const [names, setNames] = React.useState<PrivacyName[]>([])
  const [dateOfbirth, setDateOfBirth] = React.useState<string>('')
  const primaryName = names[0] || {
    prefix: null,
    first: '',
    middle: '',
    last: '',
    suffix: null,
  }
  const [loading, setLoading] = useState<boolean>(false)
  const altNames = names.slice(1)

  useEffect(() => {
    if (dataRemoval.primaryProfile) {
      const _names = new Array<PrivacyName>()
      if (dataRemoval.primaryProfile.names) {
        _names.push(dataRemoval.primaryProfile.names[0])
      }
      if (dataRemoval.primaryProfile.otherNames.length > 0) {
        _names.push(...dataRemoval.primaryProfile.otherNames)
      }

      setNames(_names)

      if (dataRemoval.primaryProfile.dateOfBirth) {
        setDateOfBirth(
          moment(dataRemoval.primaryProfile.dateOfBirth).format('MM/DD/YYYY')
        )
      }
    }
  }, [dataRemoval.primaryProfile])

  const handleNameChange = (index: number, name: PrivacyName) => {
    const newNames = [...names]
    newNames[index] = name
    setNames(newNames)
  }

  const addAltName = () => {
    setNames([
      ...names,
      { prefix: null, first: '', middle: '', last: '', suffix: null },
    ])
  }

  const removeAltName = (index: number) => {
    const newNames = [...names]
    newNames.splice(index, 1)
    setNames(newNames)
  }

  const computeNames = () => (
    <div className={styles.nameContainer}>
      {primaryName.first.length > 0 && primaryName.last.length > 0 && (
        <div className={styles.name}>
          {primaryName.first} {primaryName.last}
        </div>
      )}
      {primaryName.first.length > 0 &&
        (primaryName.middle?.length || 0) > 3 &&
        primaryName.last.length > 0 && (
        <div className={styles.name}>
          {primaryName.first} {primaryName.middle![0].toUpperCase()}{' '}
          {primaryName.last}
        </div>
      )}
      {primaryName.first.length > 0 &&
        (primaryName.middle?.length || -1) > 0 &&
        primaryName.last.length > 0 && (
        <div className={styles.name}>
          {primaryName.first} {primaryName.middle} {primaryName.last}
        </div>
      )}
    </div>
  )

  const formatBirthday = useCallback(
    (value: string) => {
      if (dateOfbirth.length === 2 || dateOfbirth.length === 5) {
        const last = value.charAt(value.length - 1)
        setDateOfBirth(`${dateOfbirth}/${last}`)
      }
    },
    [dateOfbirth]
  )

  const onChangeBirthday = useCallback(
    (value: string) => {
      if (value.length > 10) {
        return
      }

      setDateOfBirth(value)

      if (value.length > dateOfbirth.length) {
        formatBirthday(value)
      }
    },
    [dateOfbirth]
  )

  const saveAndContinue = async () => {
    setLoading(true)
    try {
      if (names.length === 0) {
        throw new Error('Please enter your name.')
      }

      if (
        dateOfbirth &&
        dateOfbirth.length > 0 &&
        moment(dateOfbirth, 'MM/DD/YYYY').isValid()
      ) {
        await dataRemoval.saveBirthday(
          moment(dateOfbirth, 'MM/DD/YYYY').toDate()
        )
      } else {
        throw new Error(
          'Please enter your date of birth in format MM/DD/YYYY.'
        )
      }

      await dataRemoval.saveNames(names)
      onStepComplete()
    } catch (e) {
      setLoading(false)
      Notifications.error(
        e.message || 'Something went wrong. Please try again later.'
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormHeader
          title={'Personal Information'}
          description={
            'This will allow us to search all data brokers for any matches of your personal information.'
          }
          image={PersonalInfoImage}
        />
        <br />
        <FormContainer>
          <FormTextField
            required={true}
            pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
            label={'First Name'}
            value={names[0]?.first || ''}
            onChange={value => {
              const name = names[0] || {
                prefix: null,
                first: '',
                middle: '',
                last: '',
                suffix: null,
              }
              handleNameChange(0, { ...name, first: value })
            }}
          />
          <FormTextField
            required={false}
            pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
            label={'Middle Name'}
            value={names[0]?.middle || ''}
            onChange={value => {
              const name = names[0] || {
                prefix: null,
                first: '',
                middle: '',
                last: '',
                suffix: null,
              }
              handleNameChange(0, { ...name, middle: value })
            }}
          />
          <FormTextField
            required={true}
            pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
            label={'Last Name'}
            value={names[0]?.last || ''}
            onChange={value => {
              const name = names[0] || {
                prefix: null,
                first: '',
                middle: '',
                last: '',
                suffix: null,
              }
              handleNameChange(0, { ...name, last: value })
            }}
          />
        </FormContainer>
        {primaryName.first.length > 0 && primaryName.last.length > 0 && (
          <div className={styles.namesWeLookFor}>
            <div className={styles.label}>We will look for:</div>
            {computeNames()}
          </div>
        )}
        <div className={styles.alternateNames}>
          <div className={styles.label}>Alternate names or (mis)spellings</div>
          <div className={styles.bullets}>
            <div className={styles.label}>
              Many people don’t have any, but feel free to add any, especially
              if:
            </div>
            <div className={styles.bullet}>
              • Your name is often misspelled (add the common mistakes - first
              or last)
            </div>
            <div className={styles.bullet}>
              • You got married, or divorced and changed your name.
            </div>
            <div className={styles.bullet}>
              • Your name is Marianne but you usually go by &quot;Mary&quot; or
              &quot;Stephen / Steve&quot;, etc.
            </div>
          </div>
          {altNames.map((name, index) => (
            <div key={index} className={styles.altNameForm}>
              <FormContainer>
                <FormTextField
                  required={true}
                  pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
                  label={'First Name'}
                  value={name.first}
                  onChange={value => {
                    handleNameChange(index + 1, { ...name, first: value })
                  }}
                />
                <FormTextField
                  required={false}
                  pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
                  label={'Middle Name'}
                  value={name.middle || ''}
                  onChange={value => {
                    handleNameChange(index + 1, { ...name, middle: value })
                  }}
                />
                <FormTextField
                  required={true}
                  pattern={'^[^\x00-\x1F!-&(-,\\/:-@[-_\x7B-\x7F]{1,50}$'}
                  label={'Last Name'}
                  value={name.last}
                  onChange={value => {
                    handleNameChange(index + 1, { ...name, last: value })
                  }}
                />
              </FormContainer>
              <div
                className={styles.removeAltName}
                onClick={() => {
                  removeAltName(index + 1)
                }}
              >
                <Image src={FormRemove} />
              </div>
            </div>
          ))}
          <div className={styles.addName} onClick={addAltName}>
            + Add another name
          </div>
        </div>
        <div className={styles.dateOfbirth}>
          <FormDateField
            label={'Date of Birth'}
            value={dateOfbirth}
            onChange={onChangeBirthday}
          />
          <span className={styles.hint}>Format mm/dd/yyyy</span>
        </div>
      </div>
      <Button
        primary={true}
        size="large"
        loading={loading}
        onClick={saveAndContinue}
        className={styles.saveButton}
      >
        Save and Continue
      </Button>
      <br />
    </div>
  )
}

export { PersonalInformation }
